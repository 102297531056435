import React, { useEffect, useState } from 'react';
import { Item, List, Box, Typography, Icon } from '@worthy-npm/worthy-common-ui-components';
import { Step, StepContainer, StepGridContainer } from '../../../styles/common';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectItemType, selectShape, updateShape } from '../../../slices/submitSlice';
import { StepProps } from '../common';
import { itemShapes } from '../../../data/ItemDetails';
import { parametrize } from '../../../lib/commonUtils';
import StepTitle from '../../stepTitle';
import GA from '../../../data/GA';

function StepShape({ active, next, idx, stepName, prev, title, stepCaption, props }: StepProps) {
  const dispatch = useAppDispatch();
  const shape = useAppSelector(selectShape);
  const itemType = useAppSelector(selectItemType);

  const [busy, setBusy] = useState(false);

  useEffect(() => {
    if (!active) return;

    if (itemType === 'Ring') {
      GA.stepView(
        stepName,
        undefined,
        undefined,
        undefined,
        undefined,
        'ringSubmissionFunnel',
        'shapeStepView',
      );
    } else {
      GA.stepView(stepName);
    }
  }, [active]);

  return (
    <StepContainer data-automation="shape-step">
      <Step>
        <StepTitle
          idx={idx}
          prev={prev}
          stepCaption={stepCaption}
          stepTitle={title || 'What is the shape of the largest diamond?'}
          stepName={stepName}
          stepNotice="Select an option below"
          optionalProps={props || {}}
        />

        <StepGridContainer>
          <List spacing={2}>
            {itemShapes.map((item) => (
              <Item
                data-automation={`shape-step-item-${parametrize(item.code)}`}
                key={item.code}
                variant="outlined"
                onClick={(e) => {
                  if (busy) return;
                  setBusy(true);
                  if (itemType === 'Ring') {
                    GA.select({ step: stepName, value: item.code, value3: 'shapeStepNext' });
                  } else {
                    GA.select({ step: stepName, value: item.code });
                  }
                  dispatch(updateShape(item.code));
                  setTimeout(() => {
                    next(e);
                    setTimeout(() => {
                      setBusy(false);
                    }, 600);
                  }, 600);
                }}
                style={{ cursor: 'pointer' }}
              >
                <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <img src={item.img} alt={item.text} height="28px" />
                    <Typography
                      variant="subtitle1"
                      color="text.primary"
                      paddingLeft="12px"
                      textTransform="capitalize"
                    >
                      {item.text}
                    </Typography>
                  </Box>
                  <Box data-automation="shape-step-item-checked">
                    {item.code === shape ? <Icon.GreenCheck display="block" /> : null}
                  </Box>
                </Box>
              </Item>
            ))}
          </List>
        </StepGridContainer>
      </Step>
    </StepContainer>
  );
}

export default StepShape;
