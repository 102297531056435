import React, { useEffect, useState } from 'react';
import { List, Item, Typography, Box, Icon } from '@worthy-npm/worthy-common-ui-components';
import { StepContainer, StepGridContainer, Step } from '../../../styles/common';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectItemType, updateSetting, selectSetting } from '../../../slices/submitSlice';
import { StepProps } from '../common';
import { parametrize } from '../../../lib/commonUtils';
import StepTitle from '../../stepTitle';
import GA from '../../../data/GA';
import { itemSettings } from '../../../data/ItemDetails';

function StepSetting({ active, next, idx, stepName, prev, title, stepCaption, props }: StepProps) {
  const dispatch = useAppDispatch();
  const setting = useAppSelector(selectSetting);
  const itemType = useAppSelector(selectItemType);
  const [busy, setBusy] = useState(false);

  useEffect(() => {
    if (!active) return;

    GA.stepView(
      stepName,
      undefined,
      undefined,
      undefined,
      undefined,
      'ringSubmissionFunnel',
      'settingsStepView',
    );
  }, [active]);

  return (
    <StepContainer data-automation="setting-step">
      <Step id="step">
        <StepTitle
          idx={idx}
          prev={prev}
          stepTitle={title || `What is the style of your ${itemType.toLowerCase()}?`}
          stepName={stepName}
          stepCaption={stepCaption}
          stepNotice="Select an option below"
          optionalProps={props || {}}
        />
        <StepGridContainer>
          <List>
            {itemSettings.map((itemSetting) => (
              <Item
                data-automation={`setting-step-item-${parametrize(itemSetting.code)}`}
                key={itemSetting.code}
                variant="outlined"
                onClick={(e) => {
                  if (busy) return;
                  setBusy(true);

                  if (itemType === 'Ring') {
                    GA.select({
                      step: stepName,
                      value: itemSetting.code,
                      value3: 'settingStepNext',
                    });
                  } else {
                    GA.select({ step: stepName, value: itemSetting.code });
                  }
                  dispatch(updateSetting(itemSetting.code));
                  setTimeout(() => {
                    next(e);
                    setTimeout(() => {
                      setBusy(false);
                    }, 600);
                  }, 600);
                }}
                style={{ cursor: 'pointer', padding: '10px 14px', marginTop: '8px' }}
              >
                <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <img src={itemSetting.icon} alt={itemSetting.code} />
                    <Typography
                      variant="subtitle1"
                      color="text.primary"
                      paddingLeft="15px"
                      textTransform="capitalize"
                    >
                      {itemSetting.code}
                    </Typography>
                  </Box>
                  <Box data-automation="setting-step-item-checked">
                    {itemSetting.code === setting ? <Icon.GreenCheck display="block" /> : null}
                  </Box>
                </Box>
              </Item>
            ))}
          </List>
        </StepGridContainer>
      </Step>
    </StepContainer>
  );
}

export default StepSetting;
