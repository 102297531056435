import React from 'react';
import styled from '@emotion/styled/macro';
import { GENERAL_COLOR_VALUES, GeneralColorNames } from '../clarity';
import { OptionalStepPropsI } from './steps/common';

const BackButtonIcon = '/images/icons/mui_back_button.svg';

const Container = styled.div`
  text-align: center;
  @media (min-width: 1200px) {
    max-width: 1200px;
    margin: auto;
    padding: 0 28px;
  }
`;

const BackButton = styled.a<any>`
  cursor: pointer;
  position: absolute;
  left: -8px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  border: none;
  background: url(${BackButtonIcon}) 50% 50% no-repeat;
  background-size: 10px 18px;
`;

const Name = styled.div`
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: ${GENERAL_COLOR_VALUES[GeneralColorNames.primary_700_main]};
`;

const Notice = styled.div`
  margin-top: 14px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #999999;
`;

const Wrapper = styled.div`
  margin-top: 8px;
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
`;

interface TitleProps {
  fontSize?: string;
  fontFamily?: string;
}

const Title = styled.div<TitleProps>`
  font-family: ${(props) => (props.fontFamily ? props.fontFamily : 'Roboto')};
  font-style: normal;
  font-weight: 500;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '28px')};
  line-height: 120%;
  color: ${GENERAL_COLOR_VALUES[GeneralColorNames.gray_601]};
  margin: auto 20px;
`;

interface MuiStepTitleProps {
  idx: number;
  prev: React.MouseEventHandler<HTMLElement> | false;
  stepName: string;
  stepTitle: string;
  stepNotice: string;
  stepCaption: string;
  optionalProps: OptionalStepPropsI;
}

function StepTitle({
  idx,
  prev,
  stepName,
  stepTitle,
  stepNotice,
  stepCaption,
  optionalProps,
}: MuiStepTitleProps) {
  const disableBackButton = optionalProps.disableBackButton || false;
  const fontSize = optionalProps.fontSize || '28px';
  const fontFamily = optionalProps.fontFamily || 'Roboto';

  return (
    <Container data-automation="step-title-container">
      <Name data-automation="step-name">{stepCaption}</Name>
      <Wrapper>
        <Title data-automation="step-title" fontSize={fontSize} fontFamily={fontFamily}>
          {stepTitle}
        </Title>

        {!prev || disableBackButton ? null : (
          <BackButton
            data-testid="back-button"
            data-automation={`wizard-prev-button-${idx}`}
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              prev(e);
            }}
          />
        )}
      </Wrapper>
      {stepNotice ? <Notice data-automation="step-notice">{stepNotice}</Notice> : null}
    </Container>
  );
}

export default StepTitle;
