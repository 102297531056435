/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-boolean-value */

import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled/macro';
import { LinearProgress, Box, Divider } from '@worthy-npm/worthy-common-ui-components';
import { useMobileVersion, useAppDispatch, useAppSelector, useSmallerVersion } from '../app/hooks';
import {
  nextStep,
  prevStep,
  restartStep,
  selectFlow,
  selectSecondarySubset,
  selectSubmitAPI,
  selectUser,
  selectWizard,
  selectWizardHistoryLength,
  submitItem,
  selectCurrentVertexId,
  wizardUseLabel,
  wizardEnterVertexById,
  wizardHistoryBack,
  wizardInitHistory,
  wizardRestart,
  wizardFinish,
  SubmitState,
} from '../slices/submitSlice';
import ProgressBar from './progrssBar/progressBar';
import {
  getProgressStepCount,
  getSubmitIndex,
  getVisibleSteps,
  getProgressStepIndex,
  LabeledDirectedGraph,
  Vertex,
} from '../lib/wizard';
import { StepProps } from './steps/common';
import { IsMobileProp } from '../styles/commonPropType';
import RetryDialog from './retryDialog';
import ItemRejection from './itemRejection';
import GA from '../data/GA';
import { LoadingContainer, LoadingDiamondImg, LoadingOverlay } from './overlay';

const BackButtonIcon = 'images/icons/back_button.svg';

const isPreRegRejection = process.env.REACT_APP_PRE_REG_REJECTION === 'true';

export interface IStep {
  comp: React.FC<StepProps>;
  name: string;
  caption: string;
  title?: string;
  hidden?: boolean;
  itemRequired?: boolean;
  skipOnlogin?: boolean;
  secondarySubset?: string;
  key?: string;
  props?: object;
  disableProgressBar?: boolean; // disable progress bar for this step
  disappearFromProgressBar?: boolean; // exclude from progressBar steps calculation
  disableBackButton?: boolean; // disable going back
  staticProgressBar?: boolean; // static line bar
}

export interface IStep2 extends IStep {
  exits?: { [label: string]: string };
  hiddenFns?: [(state: SubmitState) => boolean];
  final?: boolean;
}

interface WizardProps {
  steps: IStep[];
  oldStyle?: boolean;
}

const WizardContainer = styled.div<IsMobileProp>`
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: ${(props: any) => (props.isMobile ? 0 : 70)}px;
  overflow: hidden;
`;

const CommonWizardContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const CommonContentContainer = styled.div`
  height: 100%;
  width: 100%;
  margin: 0 auto;
  position: relative;
`;

const ContentContainer = styled.div`
  height: 100%;
  max-width: 1300px;
  margin: 0 auto;
  position: relative;
`;
const ProgressContainer = styled.div<IsMobileProp>`
  height: ${(props: any) => (props.isMobile ? 0 : 70)}px;
  position: fixed;
  bottom: 0px;
  width: 100%;
`;

const BackButton = styled.button`
  width: 20px;
  height: 40px;
  cursor: pointer;
  border: none;
  background: url(${BackButtonIcon}) 50% 50% no-repeat;
  background-size: contain;
  margin-left: 0;
  position: absolute;
  left: 30px;
  top: 100px;
  z-index: 5;
`;

const StepContainer = styled.div`
  max-width: 100%;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: row-reverse;
  overflow-x: hidden;
`;

const StepStyle = styled.div<{ active: boolean; left: boolean }>`
  position: absolute;
  justify-content: center;
  transition: opacity 2s, left 1.5s;
  opacity: ${(props: any) => (props.active ? 1 : 0)};
  left: ${(props: any) => (props.active ? '0' : props.left ? '200%' : '-200%')};
  overflow: ${(props: any) => (props.active ? 'auto' : 'hidden')};
  max-height: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 100%;
`;

function Step({ steps, idx, next, prev, props }: any) {
  const { stepIndex } = useAppSelector(selectWizard);
  const step = steps[idx];
  const WrappedComp = step.comp;
  const active = stepIndex === idx;

  return (
    <StepStyle active={active} left={stepIndex < idx}>
      <WrappedComp
        idx={idx}
        stepName={step.name}
        title={step.title}
        next={next}
        prev={prev}
        props={props}
        active={active}
        stepCaption={step.caption}
      />
    </StepStyle>
  );
}

function Wizard({ steps, oldStyle }: WizardProps) {
  if (_.isEmpty(steps)) throw new Error('At least one step is required for this component');

  const dispatch = useAppDispatch();
  const { stepIndex, loading } = useAppSelector(selectWizard);
  const { isLoggedIn } = useAppSelector(selectUser);
  const secondarySubset = useAppSelector(selectSecondarySubset);
  const { itemId, itemAutoRejected, retryIsNeeded } = useAppSelector(selectSubmitAPI);
  const isMobile = useMobileVersion();
  const isSmallMobile = useSmallerVersion();
  const visibleSteps = getVisibleSteps(steps, !!isLoggedIn, secondarySubset);
  const progressStepCount = getProgressStepCount(steps, !!isLoggedIn, secondarySubset);
  const [filteredStepIndex, setFilteredStepIndex] = useState(stepIndex);
  const [showAutoReject, setShowAutoReject] = useState(false);
  const byImage = useAppSelector(selectFlow);

  const stepsWithoutComp: Omit<IStep, 'comp'>[] = steps.map((step) => ({
    ...step,
    comp: null,
  }));
  const submitIndex = getSubmitIndex(steps, !!isLoggedIn, secondarySubset);
  const rejectIndex =
    isPreRegRejection || isLoggedIn
      ? submitIndex
      : steps.findIndex((step) => step.name.includes('Registration'));

  // go next after submitting item
  useEffect(() => {
    // check if we get success (itemId has value) from submit API,
    if (itemId && submitIndex === stepIndex) {
      if (rejectIndex === stepIndex && itemAutoRejected && !byImage) {
        setShowAutoReject(true);
      } else {
        dispatch(nextStep(stepsWithoutComp));
      }
    }
  }, [itemId, itemAutoRejected, stepIndex, byImage]);

  // logic when step is presented
  useEffect(() => {
    setFilteredStepIndex(visibleSteps.findIndex((step) => step.name === steps[stepIndex].name));
  }, [stepIndex]);

  const next = async () => {
    if (stepIndex === submitIndex) {
      const status = await dispatch(submitItem());
      if (status.meta.requestStatus === 'rejected') {
        return { success: false };
      }
      return { success: true };
    }
    // for delayed autoReject
    if (rejectIndex === stepIndex && itemAutoRejected && !byImage) {
      setShowAutoReject(true);
      return { success: true };
    }
    dispatch(nextStep(stepsWithoutComp));
    return { success: true };
  };

  const prev = () => {
    GA.previousClick(steps[stepIndex].name);
    dispatch(prevStep(stepsWithoutComp));
  };

  const restart = () => {
    dispatch(restartStep(stepsWithoutComp));
  };

  return !oldStyle ? (
    <CommonWizardContainer>
      {!steps[stepIndex].disableProgressBar && !showAutoReject && !retryIsNeeded && (
        <Box top="-6px" position="relative">
          <LinearProgress
            data-automation="progress-bar"
            value={getProgressStepIndex(visibleSteps, steps[stepIndex])}
            max={progressStepCount}
            sx={{ padding: '6px 0;' }}
          />
        </Box>
      )}
      {steps[stepIndex].staticProgressBar && !showAutoReject && !retryIsNeeded && (
        <Divider
          aria-hidden="true"
          sx={{
            width: isSmallMobile ? '90%' : '50%',
            borderColor: '#D2D5D7',
            margin: '0 auto',
          }}
        />
      )}
      <CommonContentContainer>
        <StepContainer>
          {loading > 0 && (
            <LoadingContainer>
              {loading && (
                <LoadingOverlay background="#f6f8ff">
                  <LoadingDiamondImg />
                </LoadingOverlay>
              )}
            </LoadingContainer>
          )}
          {showAutoReject ? (
            <ItemRejection />
          ) : (
            steps.map((step, i) => (
              <Step
                steps={steps}
                key={step.name}
                next={next}
                prev={!steps[stepIndex].disableBackButton && prev}
                idx={i}
                props={step.props}
              />
            ))
          )}
        </StepContainer>
      </CommonContentContainer>
      {retryIsNeeded && <RetryDialog retryHook={next} restartHook={restart} />}
    </CommonWizardContainer>
  ) : (
    <WizardContainer isMobile={isMobile}>
      <ContentContainer>
        {!steps[stepIndex].disableBackButton && !isMobile && !loading && stepIndex > 0 && (
          <BackButton data-automation={`wizard-prev-button-${stepIndex}`} onClick={prev} />
        )}
        <StepContainer>
          {loading > 0 && (
            <LoadingContainer>
              {loading && (
                <LoadingOverlay background="#f6f8ff">
                  <LoadingDiamondImg />
                </LoadingOverlay>
              )}
            </LoadingContainer>
          )}
          {showAutoReject ? (
            <ItemRejection oldStyle />
          ) : (
            steps.map((step, i) => (
              <Step
                steps={steps}
                key={step.name}
                next={next}
                prev={!steps[stepIndex].disableBackButton && prev}
                idx={i}
              />
            ))
          )}
        </StepContainer>
      </ContentContainer>
      <ProgressContainer isMobile={isMobile}>
        {isMobile || steps[stepIndex].disableProgressBar ? null : (
          <ProgressBar
            indx={filteredStepIndex}
            count={progressStepCount}
            caption={steps[stepIndex].caption}
          />
        )}
      </ProgressContainer>
      {retryIsNeeded && <RetryDialog retryHook={next} restartHook={restart} />}
    </WizardContainer>
  );
}
Wizard.defaultProps = {
  oldStyle: false,
};
export default Wizard;

interface Wizard2Props {
  stepsGraph: LabeledDirectedGraph;
}

function Step2({ step, exit, props }: any) {
  const WrappedComp = step.comp;

  return (
    <StepStyle active={true} left={false}>
      <WrappedComp
        idx={0}
        stepName={step.name}
        title={step.title}
        next={() => exit('next')}
        prev={() => exit('prev')}
        exit={exit}
        props={props}
        active={true}
        stepCaption={step.caption}
      />
    </StepStyle>
  );
}

export function Wizard2({ stepsGraph }: Wizard2Props) {
  const dispatch = useAppDispatch();
  const currentVertexId = useAppSelector(selectCurrentVertexId);
  const { isLoggedIn } = useAppSelector(selectUser);
  const secondarySubset = useAppSelector(selectSecondarySubset);
  const { itemId, itemAutoRejected, retryIsNeeded } = useAppSelector(selectSubmitAPI);
  const isMobile = useMobileVersion();
  const [showAutoReject, setShowAutoReject] = useState(false);
  const { loading } = useAppSelector(selectWizard);
  const byImage = useAppSelector(selectFlow);

  const currentVertex = currentVertexId
    ? stepsGraph.getVertex(currentVertexId)
    : stepsGraph.getRootVertex();
  if (!currentVertex) {
    throw new Error('Vertex not found in graph');
  }
  const currentStep = currentVertex.step;

  const depth = currentVertexId ? stepsGraph.depthForVertex(currentVertexId) : 0;
  const historyLength = useAppSelector(selectWizardHistoryLength);

  const prev = () => {};

  const restart = () => {
    dispatch(wizardRestart({ vertexId: stepsGraph.getRootVertex()?.id || '', stepsGraph }));
  };

  // Do this once to start the wizard
  useEffect(() => {
    restart();
  }, []);

  const disableProgressBar =
    currentVertexId && stepsGraph.getVertex(currentVertexId)?.flags?.disableProgressBar;

  const renderProgressBar = () => {
    return (
      !disableProgressBar &&
      !showAutoReject &&
      !retryIsNeeded && (
        <Box top="-6px" position="relative">
          <LinearProgress
            data-automation="progress-bar"
            value={historyLength + 1}
            max={historyLength + depth}
            sx={{ padding: '6px 0;' }}
          />
        </Box>
      )
    );
  };

  const exit = async (label: string) => {
    if (label === 'prev') {
      GA.previousClick(currentVertex.step.name);
      dispatch(wizardHistoryBack());
    } else if (label === 'next' && currentVertex.flags?.final) {
      dispatch(wizardFinish());
    } else {
      dispatch(wizardUseLabel({ label, stepsGraph }));
    }
  };

  const next = async () => exit('next');

  const retrySubmit = async () => {
    const status = await dispatch(submitItem());
    if (status.meta.requestStatus === 'rejected') {
      return { success: false };
    }
    return { success: true };
  };

  return (
    <CommonWizardContainer>
      {renderProgressBar()}
      <CommonContentContainer>
        <StepContainer>
          {loading > 0 && (
            <LoadingContainer>
              {loading && (
                <LoadingOverlay background="#f6f8ff">
                  <LoadingDiamondImg />
                </LoadingOverlay>
              )}
            </LoadingContainer>
          )}
          {showAutoReject ? (
            <ItemRejection />
          ) : (
            <Step2
              step={currentStep}
              key={currentStep.name}
              exit={exit}
              props={{ ...currentStep.props, disableBackButton: currentStep.disableBackButton }}
            />
          )}
        </StepContainer>
      </CommonContentContainer>
      {retryIsNeeded && <RetryDialog retryHook={retrySubmit} restartHook={restart} />}
    </CommonWizardContainer>
  );
}
