import React, { useEffect } from 'react';
import { mainTheme, ThemeProvider } from '@worthy-npm/worthy-common-ui-components';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import StepSelectItemType from '../components/submission/step_select_item_type';
import CommonLayout from './layout/commonLayout';
import { useAppDispatch } from '../app/hooks';
import { restartStep } from '../slices/submitSlice';

const step = {
  comp: StepSelectItemType,
  name: 'StepSelectItemType',
  caption: 'Item Type',
  title: 'What type of item do you have?',
  disableBackButton: true,
  disappearFromProgressBar: true,
  disableProgressBar: true,
  active: true,
};

function SubmissionPage() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(restartStep([_.omit(step, 'comp')]));
  }, [location, dispatch]);

  return (
    <ThemeProvider theme={mainTheme}>
      <CommonLayout hideCloseBtn>
        <step.comp
          idx={0}
          active
          stepName={step.name}
          title={step.title}
          stepCaption={step.caption}
        />
      </CommonLayout>
    </ThemeProvider>
  );
}

export default SubmissionPage;
