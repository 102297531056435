import React, { PropsWithChildren } from 'react';
import styled from '@emotion/styled/macro';
import { GeneralColorNames, GENERAL_COLOR_VALUES } from '../clarity';
import { useAppSelector, useMobileVersion } from '../app/hooks';
import { selectSubmitData } from '../slices/submitSlice';

const Container = styled.div`
  height: 42px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 50px 0;
`;
const ContainerWithoutPrev = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  padding-top: 1rem;
`;
const BackButton = styled.a<any>`
  cursor: pointer;
  text-decoration: none;
  line-height: 100%;
  align-items: center;
  width: 60px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  color: ${GENERAL_COLOR_VALUES[GeneralColorNames.primary_700_main]};
`;

const itemsExclude = ['watch', 'earrings'];

function CTAContainer({
  idx,
  prev,
  children,
}: PropsWithChildren<{
  idx: number;
  // eslint-disable-next-line react/require-default-props
  prev?: React.MouseEventHandler<HTMLButtonElement> | null | undefined;
}>) {
  const isMobile = useMobileVersion();
  const hasCTA = children !== undefined;
  const CTAButn = children || null;
  const { type } = useAppSelector(selectSubmitData);

  return prev && isMobile && type === 'ring'
    ? ((hasCTA || idx !== 0) && (
        <Container>
          {idx !== 0 && (
            <BackButton data-automation={`wizard-prev-button-${idx}`} onClick={prev}>
              Back
            </BackButton>
          )}
          {hasCTA && CTAButn}
        </Container>
      )) ||
        null
    : (hasCTA && (
        <ContainerWithoutPrev
          style={itemsExclude.includes(type) && idx !== 0 ? { height: 'auto' } : {}}
        >
          {CTAButn}
        </ContainerWithoutPrev>
      )) ||
        null;
}

export default CTAContainer;
