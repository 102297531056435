import React, { useEffect, useState } from 'react';
import {
  Grid,
  GridItem,
  Button,
  Typography,
  Box,
  Icon,
  TextField,
  styled,
} from '@worthy-npm/worthy-common-ui-components';
import _ from 'lodash';
import { StepGridContainer, Step, StepContainer, NextStepButton } from '../../../styles/common';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  selectBrand,
  selectItemType,
  updateBrand,
  updatePremiumBrand,
  updateCustomBrand,
  selectCustomBrand,
} from '../../../slices/submitSlice';
import { FormContent, FormWrap, Notice, StepProps } from '../common';
import { parametrize } from '../../../lib/commonUtils';
import StepTitle from '../../stepTitle';
import GA from '../../../data/GA';
import { JeweleryItemsList, WatchBrandItems } from '../../../data/ItemDetails';

const ImgWrap = styled(Box)<{ shrink: boolean }>`
  aspect-ratio: 1 / 1;
  position: relative;
  max-width: 200px;
  max-height: 96px;
  width: ${(props) => (props.shrink ? '67%' : '100%')};
  align-content: center;
  display: flex;
`;

export const ImgWatch = styled('img')`
  height: auto;
  width: 100%;
  object-fit: contain;
  object-position: center;
  display: block;
`;

const renderImg = (type: string, img: string, text: string | undefined) => {
  return (
    <ImgWrap shrink={type === 'Watch'}>
      <ImgWatch src={img} alt={text} />
    </ImgWrap>
  );
};

const GridItemWrap = styled(GridItem)`
  width: 100%;
  max-width: 200px;
  margin: 4px;
  background-color: white;
  height: 93px;
  cursor: pointer;

  flex: 1 1 50%;
  box-sizing: border-box;

  @media (max-width: 480px) {
    flex: 1 1 36vw;
  }
`;

const GridWrap = styled(Grid)`
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
`;

function StepBrand({
  next,
  idx,
  stepName,
  exit,
  prev,
  title,
  stepCaption,
  active,
  props,
}: StepProps) {
  const dispatch = useAppDispatch();
  const brand = useAppSelector(selectBrand);
  const customBrand = useAppSelector(selectCustomBrand);
  const itemType = useAppSelector(selectItemType);
  const [busy, setBusy] = useState(false);
  const [isInput, setIsInput] = useState<boolean>(brand === 'other');
  const [brandName, setBrandName] = useState<string>(customBrand);

  let ItemBrands = JeweleryItemsList;
  if (itemType === 'Watch') {
    ItemBrands = WatchBrandItems;
  } else {
    const storedData = sessionStorage.getItem('PremuimBrandOreder');
    if (storedData && _.isArray(JSON.parse(storedData))) {
      ItemBrands = JSON.parse(storedData);
    } else {
      const initialBrands = _.initial(ItemBrands);
      const lastBrand = _.last(ItemBrands);
      const shuffledBrands = _.shuffle(initialBrands);
      if (lastBrand) {
        shuffledBrands.push(lastBrand);
      }
      ItemBrands = shuffledBrands;

      sessionStorage.setItem('PremuimBrandOreder', JSON.stringify(ItemBrands));
    }
  }

  useEffect(() => {
    if (!active) return;
    GA.stepView(stepName);
  }, [active]);

  const scrollToBottom = () => {
    const nextScroll = document.getElementById('brand-next-button');
    nextScroll?.scrollIntoView({ behavior: 'smooth' });
  };

  const onBrandSelect = (e: any, item: any) => {
    if (item.code === 'other') {
      dispatch(updateBrand(item.code));
      dispatch(updatePremiumBrand(false));

      setIsInput(true);

      setTimeout(() => {
        scrollToBottom();
      }, 450);
      return;
    }

    if (busy) return;
    setBusy(true);
    setIsInput(false);
    GA.select({ step: stepName, value: item.code });
    dispatch(updateBrand(item.code));
    const isPremium = props?.premiumBrands?.includes(item.code) || false;
    dispatch(updatePremiumBrand(isPremium)); // mark if the brand is premium
    setTimeout(() => {
      if (isPremium) {
        // if the brand is premium, go to directly to registration
        if (exit) exit('premium');
        else throw new Error('no exit');
      } else {
        next(e);
      }
      setTimeout(() => {
        setBusy(false);
      }, 600);
    }, 600);
  };

  const buttonTemplate = () => {
    return isInput ? (
      <Button
        disableElevation
        data-automation="brand-next-button"
        id="brand-next-button"
        sx={{
          width: '90px',
        }}
        onClick={(e) => {
          if (busy) return;
          setBusy(true);
          dispatch(updateCustomBrand(brandName));
          GA.select({ step: stepName, value: brand });
          setTimeout(() => {
            next(e);
            setTimeout(() => {
              setBusy(false);
            }, 150);
          }, 150);
        }}
        variant={itemType === 'Watch' || brandName ? 'contained' : 'filled'}
        disabled={!brandName.length && itemType === 'Watch'}
        size="large"
        endIcon={<Icon.ContinueIcon />}
      >
        {itemType === 'Watch' || brandName ? 'Next' : 'Skip'}
      </Button>
    ) : null;
  };

  return (
    <StepContainer data-automation="brand-step">
      <Step>
        <StepTitle
          idx={idx}
          prev={(e) => {
            if (exit) exit('prev');
            else prev(e);
          }}
          stepTitle={title || `What brand is your ${itemType.toLowerCase()}s?`}
          stepName={stepName}
          stepCaption={stepCaption}
          stepNotice="Select an option below"
          optionalProps={props || {}}
        />

        <StepGridContainer style={{ maxWidth: '856px' }}>
          <GridWrap>
            {ItemBrands.map((item, index) => (
              <GridItemWrap
                data-automation={`brand-step-item-${parametrize(item.code)}`}
                id={`brand-step-item-${parametrize(item.code)}`}
                key={item.code}
                onClick={(e) => {
                  onBrandSelect(e, item);
                }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  height="100%"
                  position="relative"
                  justifyContent="center"
                >
                  {item.img ? (
                    renderImg(itemType, item.img, item.text)
                  ) : (
                    <Typography
                      variant="subtitle1"
                      color="text.primary"
                      paddingLeft="12px"
                      textTransform="capitalize"
                      flexGrow={1}
                      flexShrink={1}
                      textAlign="center"
                      padding={0}
                    >
                      {item.text}
                    </Typography>
                  )}

                  <Box
                    data-automation="brand-step-item-checked"
                    position="absolute"
                    top="0"
                    right="-5px"
                  >
                    {item.code === brand ? <Icon.GreenCheck display="block" /> : null}
                  </Box>
                </Box>
              </GridItemWrap>
            ))}
          </GridWrap>

          {brand === 'other' ? (
            <FormWrap>
              <FormContent
                active={isInput}
                data-testid="brand-input-form"
                data-automation="brand-input-form"
                style={{ height: '120px' }}
              >
                <Notice>Please enter brand name</Notice>
                <TextField
                  fullWidth
                  style={{ padding: '0 4px' }}
                  inputProps={{ maxLength: 25 }}
                  data-automation="brand-input"
                  variant="outlined"
                  value={brandName}
                  onChange={(e) => {
                    setBrandName(e.target.value);
                  }}
                />
              </FormContent>
            </FormWrap>
          ) : null}

          <NextStepButton>{buttonTemplate()}</NextStepButton>
        </StepGridContainer>
      </Step>
    </StepContainer>
  );
}

export default StepBrand;
