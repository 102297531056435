import React, { useEffect, useState } from 'react';
import { Item, Typography, List, Box, Icon } from '@worthy-npm/worthy-common-ui-components';
import { Step, StepContainer, StepGridContainer } from '../../styles/common';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectItemType, selectMetalType, updateMetalType } from '../../slices/submitSlice';
import { StepProps } from './common';
import { MetalTypes } from '../../data/ItemDetails';
import StepTitle from '../stepTitle';
import { parametrize } from '../../lib/commonUtils';
import GA from '../../data/GA';

const Items = Object.values(MetalTypes);

function StepMetalType({
  active,
  next,
  idx,
  stepName,
  prev,
  title,
  stepCaption,
  props,
}: StepProps) {
  const dispatch = useAppDispatch();
  const metalType = useAppSelector(selectMetalType);
  const itemType = useAppSelector(selectItemType);
  const [busy, setBusy] = useState(false);

  useEffect(() => {
    if (!active) return;
    GA.stepView(stepName);
  }, [active]);

  return (
    <StepContainer data-automation="metal-type-step">
      <Step>
        <StepTitle
          idx={idx}
          prev={prev}
          stepTitle={title || `What metal is your ${itemType.toLowerCase()} made of?`}
          stepName={stepName}
          stepNotice="Select an option below"
          stepCaption={stepCaption}
          optionalProps={props || {}}
        />

        <StepGridContainer>
          <List spacing={2}>
            {Items.map((item: string) => (
              <Item
                data-automation={`metal-type-step-item-${parametrize(item)}`}
                key={item}
                variant="outlined"
                onClick={(e) => {
                  if (busy) return;
                  setBusy(true);
                  GA.select({ step: stepName, value: item });
                  dispatch(updateMetalType(item));
                  setTimeout(() => {
                    next(e);
                    setTimeout(() => {
                      setBusy(false);
                    }, 600);
                  }, 600);
                }}
              >
                <Box // eslint-disable-line prettier/prettier
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Typography variant="subtitle1" color="text.primary">
                    {item}
                  </Typography>

                  <Box // eslint-disable-line prettier/prettier
                    data-automation="metal-type-step-item-checked"
                  >
                    {item === metalType ? <Icon.GreenCheck display="block" /> : null}
                  </Box>
                </Box>
              </Item>
            ))}
          </List>
        </StepGridContainer>
      </Step>
    </StepContainer>
  );
}

export default StepMetalType;
