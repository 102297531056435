import React, { useEffect, useState } from 'react';
import { GridItem, Typography, Grid, Box, Icon } from '@worthy-npm/worthy-common-ui-components';
import styled from '@emotion/styled/macro';
import { Step, StepContainer, StepGridContainer } from '../../../styles/common';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectColor, updateColor } from '../../../slices/submitSlice';
import { DiamondColor } from '../../../data/ItemDetails';
import StepTitle from '../../stepTitle';
import { parametrize } from '../../../lib/commonUtils';
import GA from '../../../data/GA';
import { StepProps } from '../common';

const Items = Object.values(DiamondColor);

const GridItemColor = styled(GridItem)`
  margin: 4px;
  background-color: white;
  height: 60px;
  width: auto;
  padding: 14px 7px;
  cursor: pointer;
`;

function StepColor({ next, active, idx, stepName, prev, title, stepCaption, props }: StepProps) {
  const dispatch = useAppDispatch();
  const color = useAppSelector(selectColor);
  const [busy, setBusy] = useState(false);

  useEffect(() => {
    if (!active) return;
    GA.stepView(stepName);
  }, [active]);

  const handleColorSelect = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, item: string) => {
    if (busy) return;

    setBusy(true);
    GA.select({ step: stepName, value: item });
    dispatch(updateColor(item));
    setTimeout(() => {
      next(e);
      setTimeout(() => {
        setBusy(false);
      }, 600);
    }, 600);
  };

  return (
    <StepContainer data-automation="color-step">
      <Step>
        <StepTitle
          idx={idx}
          prev={prev}
          stepTitle={title || 'What color grade does your diamond have?'}
          stepName={stepName}
          stepNotice="Select an option below"
          stepCaption={stepCaption}
          optionalProps={props || {}}
        />

        <StepGridContainer>
          <Grid display="grid" gridTemplateColumns="33.3% 33.3% 33.3%">
            {Items.map((item: string) => (
              <GridItemColor
                data-automation={`color-step-item-${parametrize(item)}`}
                key={item}
                onClick={(e) => handleColorSelect(e, item)}
              >
                <Box
                  display="flex"
                  padding="15px 25px"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="center"
                  height="100%"
                  position="relative"
                >
                  <Typography variant="subtitle1" color="text.primary" textTransform="capitalize">
                    {item}
                  </Typography>
                  <Box data-automation="color-step-item-checked" position="absolute" right="1px">
                    {item === color ? <Icon.GreenCheck display="block" /> : null}
                  </Box>
                </Box>
              </GridItemColor>
            ))}
          </Grid>
        </StepGridContainer>
      </Step>
    </StepContainer>
  );
}

export default StepColor;
