import React, { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  Alert,
  Typography,
  Icon,
  Link,
  Select,
  MenuItem,
  Grid,
  GridItem,
  styled,
} from '@worthy-npm/worthy-common-ui-components';
import _ from 'lodash';
import { Step, StepContainer, NextStepButton, StepGridContainer } from '../../../styles/common';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  selectCaratClassification,
  selectItemType,
  updateCarat,
  updateCaratClassification,
  updateCaratRangeTo,
} from '../../../slices/submitSlice';
import { StepProps } from '../common';
import StepTitle from '../../stepTitle';
import GA from '../../../data/GA';
import CaratWeight from '../../popup/carat_weight';
import { suggestCaratThreshold } from '../../../data/constants';
import {
  CaratSizeRanges,
  stoneClassifications,
  bucketTypes,
  InitialWeightRange,
} from '../../../data/ItemDetails';
import { FormContent } from '../../../styles/commonText';
import { toCamelCase } from '../../../lib/commonUtils';

const InlineWrapper = styled('div')`
  display: inline-flex;
`;

const CheckIconStyle = styled('img')`
  position: absolute;
  width: 15px;
  right: 5px;
  top: 5px;
`;

const TipWrap = styled('div')`
  margin-top: 77px;
  display: flex;
  position: relative;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
`;

const CaratClassificationType = styled(GridItem)(({ theme, selected }) => {
  let selectedStyle = {};
  if (selected) {
    selectedStyle = {};
  }
  return {
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: '100px',
    width: '130px',
    cursor: 'pointer',
    position: 'relative',
    ...selectedStyle,
  };
});

function Weight({ active, next, stepName, idx, prev, title, stepCaption, props }: StepProps) {
  const [caratWeightPopupVisible, showCaratWeight] = useState<boolean>(false);
  const [weight, setWeight] = useState<number>(0);
  const [weightRange, setWeightRange] = useState<string>(InitialWeightRange);
  const [isValid, setIsValid] = useState<boolean>(true);
  const [nextAvailability, setNextAvailability] = useState<boolean>(false);
  const [busy, setBusy] = useState(false);

  const itemType = useAppSelector(selectItemType);
  const classification = useAppSelector(selectCaratClassification);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!active) return;

    if (itemType === 'Ring') {
      GA.stepView(
        stepName,
        undefined,
        undefined,
        undefined,
        undefined,
        'ringSubmissionFunnel',
        'caratStepView',
      );
    } else {
      GA.stepView(stepName);
    }
  }, [active]);

  const validate = (value: string): void => {
    const parsedValue = Number(value);
    if (Number.isNaN(parsedValue) || parsedValue >= 20 || parsedValue <= 0) {
      setWeight(0);
      setIsValid(false);
      setNextAvailability(false);
    } else {
      setWeight(parsedValue);
      setIsValid(true);
      setNextAvailability(true);
    }
  };

  const handleEnter = (e: any) => {
    if (e.key === 'Enter') {
      validate(e.target.value);
      if (isValid) {
        if (+e.target.value >= suggestCaratThreshold) {
          showCaratWeight(true);
          return;
        }
      }

      if (isValid && nextAvailability) {
        dispatch(updateCarat(weight));
        next(e);
      }
    }
  };

  const nextClb = (e: React.MouseEvent<HTMLButtonElement>, value: number) => {
    GA.submitNext(stepName, value.toString(), weight.toString(), 'caratDecimalPopup');
    dispatch(updateCarat(value));
    next(e);
  };

  const handleSelectRange = (e: any) => {
    setWeightRange(e.target.value);

    const range = JSON.parse(e.target.value);
    const weightFrom = range.text === 'Not Sure' || !range.from ? 0 : range.from;
    setWeight(weightFrom);
    setIsValid(true);
    setNextAvailability(true);
  };

  const getDropdown = () => (
    <>
      <Select
        labelId="carat-step-select-label"
        id="carat-step-select"
        data-automation="carat-step-select"
        value={weightRange}
        onChange={(e: any) => {
          handleSelectRange(e);
        }}
        inputProps={{ 'aria-label': 'Without label' }}
        style={{ minWidth: '13.9em', textAlign: 'center' }}
        renderValue={() =>
          JSON.parse(weightRange).text.length !== 0
            ? JSON.parse(weightRange).text
            : 'Select carat range'
        }
        displayEmpty
      >
        <MenuItem disabled value={InitialWeightRange}>
          <em>Select carat range</em>
        </MenuItem>
        {CaratSizeRanges.map((range) => (
          <MenuItem
            key={`carat-step-select-${range.text}`}
            value={`{"from": ${range.from}, "to": ${range.to}, "text": "${range.text}"}`}
          >
            {range.text}
          </MenuItem>
        ))}
      </Select>
      <Grid flexWrap="nowrap" gap={2} justifyContent="center">
        {stoneClassifications.map((classificationType) => (
          <CaratClassificationType
            data-automation={`carat-classification-${classificationType.eventText}`}
            key={classificationType.text}
            selected={classificationType.text === classification}
            onClick={() => dispatch(updateCaratClassification(classificationType.text))}
          >
            {classificationType.text === classification && (
              <CheckIconStyle alt={classificationType.text} src="images/icons/v_success.svg" />
            )}
            <img src={classificationType.img} alt={classificationType.text} />
            <Typography noWrap>{classificationType.text}</Typography>
          </CaratClassificationType>
        ))}
      </Grid>
    </>
  );

  const getInputNumberField = () => (
    <InlineWrapper>
      <TextField
        data-automation="central-carat-weight-input"
        inputProps={{ inputMode: 'decimal', type: 'number', step: 0.1, min: 0.1, max: 20.0 }}
        error={!isValid}
        placeholder="0.0 ct."
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          validate(e.target.value);
          setWeightRange(InitialWeightRange);
        }}
        onKeyDown={handleEnter}
        sx={{ input: { textAlign: 'center', minWidth: '12.2em' } }}
        disabled={bucketTypes.includes(itemType)}
      />
    </InlineWrapper>
  );

  const getForm = () => {
    if (bucketTypes.includes(itemType)) {
      return getDropdown();
    }

    return getInputNumberField();
  };

  const getTip = () =>
    !bucketTypes.includes(itemType) ? (
      <Alert style={{ marginTop: '20px' }} variant="outlined" severity="info">
        <Typography rowGap={3}>Tip: Most common diamond Carat weight is 0.9CT.</Typography>
        <Link
          underline="none"
          style={{ cursor: 'pointer' }}
          data-automation="intercom-open-step-carat"
          onClick={() => {
            GA.intercomClick(stepName);
          }}
        >
          I need help with this
        </Link>
      </Alert>
    ) : null;

  const handleContinueClicked = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!busy) {
      if (weight >= suggestCaratThreshold) {
        showCaratWeight(true);
        return;
      }

      setBusy(true);
      dispatch(updateCarat(weight));

      if (bucketTypes.includes(itemType)) {
        const range = JSON.parse(weightRange);
        const caratClass = _.find(stoneClassifications, { text: classification })?.eventText || '';

        if (range.text) {
          const rangeCamel = range.text.replace(' ct.', '');
          GA.submitCaratWeight(toCamelCase(rangeCamel), caratClass);
          dispatch(updateCaratRangeTo(range.to));
        }
      } else {
        GA.submitNext(stepName, weight.toString());
      }

      next(e);
      setTimeout(() => {
        setBusy(false);
      }, 150);
    }
  };

  return (
    <StepContainer data-automation="central-carat-weight-step">
      <Step>
        <StepTitle
          idx={idx}
          stepCaption={stepCaption}
          prev={prev}
          stepTitle={title || 'Great! What is the largest diamond’s carat weight?'}
          stepName={stepName}
          stepNotice=""
          optionalProps={props || {}}
        />
        <StepGridContainer>
          <FormContent>{getForm()}</FormContent>
          {getTip()}
          <NextStepButton>
            <Button
              disableElevation
              data-automation="central-carat-weight-next-button"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleContinueClicked(e)}
              variant="contained"
              size="large"
              disabled={!nextAvailability}
              endIcon={<Icon.ContinueIcon />}
            >
              Continue
            </Button>
          </NextStepButton>
        </StepGridContainer>
        {itemType === 'Ring' && (
          <TipWrap>
            <Alert
              style={{
                justifyContent: 'center',
                maxWidth: '550px',
              }}
              variant="outlined"
              severity="info"
            >
              <Typography>Select the individual weight of the single largest diamond</Typography>
            </Alert>
          </TipWrap>
        )}

        {caratWeightPopupVisible && (
          <CaratWeight
            next={nextClb}
            setPopupVisible={showCaratWeight}
            itemCarat={weight}
            stepName={stepName}
          />
        )}
      </Step>
    </StepContainer>
  );
}

export default Weight;
