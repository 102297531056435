import { CookieStore } from '../services/cookieStore';

// const EXPERIMENTS_COOKIE_NAME = 'wcfexp';
const EXPERIMENT_SEPARATOR = '||';
const VARIANT_SEPARATOR = '::';

function getExperimentCookieValue(experimentName: string) {
  // Cookie format: _wor_opt_exp_=experiment1±variant1§experiment2±variant2§experiment3±variant3
  const experimentsCookie: string = CookieStore.getExperiments();
  const experimentStrings = experimentsCookie.split(EXPERIMENT_SEPARATOR);
  const experiments = experimentStrings.map((experimentString: string) =>
    experimentString.split(VARIANT_SEPARATOR),
  );
  const exp = experiments.find((experiment) => experiment[0] === experimentName);
  return exp ? exp[1] : undefined;
}

export function getExperiment(
  experimentName: string,
  defaultValue: string | undefined = undefined,
) {
  console.debug(`experiment ${experimentName} defaultValue: `, defaultValue);
  const experimentValue = getExperimentCookieValue(experimentName) || defaultValue;

  console.debug(`experiment ${experimentName} returning: `, experimentValue);
  return experimentValue;
}

// export function getBooleanExperiment(
//   experimentName: string,
//   defaultValue: string | undefined = undefined,
// ) {
//   const experiment = getExperiment(experimentName, defaultValue);
//   return !!(experiment && experiment !== '0');
// }

// export function getNumericExperiment(
//   experimentName: string,
//   defaultValue: number | undefined = undefined,
// ) {
//   const experiment = getExperiment(experimentName, (defaultValue || 0).toString());
//   console.log('experiment', experiment);
//   return Number(experiment) || 0;
// }
