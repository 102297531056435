import { Box, FormControlLabel, styled } from '@worthy-npm/worthy-common-ui-components';
import React from 'react';

interface SvgProps {
  checked: boolean;
}

function BoxSvg({ checked }: SvgProps) {
  return (
    <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M33 11.7896H1V30.3159H33V11.7896Z"
        stroke={checked ? '#1565c0' : 'black'}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.36914 26.9474V15.158H12.7902V20.2106H21.2112V15.158H29.6323V26.9474H4.36914Z"
        stroke={checked ? '#1565c0' : 'black'}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 15.158V16.8422"
        stroke={checked ? '#1565c0' : 'black'}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33 8.42117C33 4.7159 29.9684 1.68433 26.2632 1.68433H7.73684C4.03158 1.68433 1 4.7159 1 8.42117V11.7896H33V8.42117Z"
        stroke={checked ? '#1565c0' : 'black'}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.789 1.68433C7.73633 1.68433 7.73633 8.42117 7.73633 8.42117V11.7896"
        stroke={checked ? '#1565c0' : 'black'}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.2109 1.68433C26.2636 1.68433 26.2636 8.42117 26.2636 8.42117V11.7896"
        stroke={checked ? '#1565c0' : 'black'}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function PaperSvg({ checked }: SvgProps) {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.3043 2.39111H33V32.9998H1V2.39111H5.17391"
        stroke={checked ? '#1565c0' : 'black'}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.3043 19.087L10.739 13.5217L5.17383 19.087V1H16.3043V19.087Z"
        stroke={checked ? '#1565c0' : 'black'}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.4785 10.7393H28.8263"
        stroke={checked ? '#1565c0' : 'black'}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.4785 14.9131H28.8263"
        stroke={checked ? '#1565c0' : 'black'}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.4785 19.0869H28.8263"
        stroke={checked ? '#1565c0' : 'black'}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.17383 23.2607H28.826"
        stroke={checked ? '#1565c0' : 'black'}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.17383 27.4346H23.2608"
        stroke={checked ? '#1565c0' : 'black'}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const ConditionWrapper = styled(Box)<{ img?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FormControlLabelWrap = styled(FormControlLabel)<{ checked: boolean }>`
  width: 20em;
  max-width: 320px;
  justify-content: space-between;
  border-radius: 16px;
  padding: 10px 22px;
  border: 1px solid #e5e5e5;
  background: #ffffff;
  margin: 0;
  ${(props: any) =>
    props.checked
      ? `
   background-color: transparent;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border: 1px solid rgba(58, 95, 227, 0.5);
    color: rgb(58, 95, 227);
    box-shadow: none;`
      : ''};
`;

const FormLabelWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 14px;
`;

const ContentWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

export {
  ConditionWrapper,
  FormLabelWrapper,
  FormControlLabelWrap,
  ContentWrapper,
  PaperSvg,
  BoxSvg,
};
