import React, { useEffect } from 'react';
import { ThemeProvider, mainTheme } from '@worthy-npm/worthy-common-ui-components';
import CommonLayout from './layout/commonLayout';
import { IStep2, Wizard2 } from '../components/wizard';
import { useAppDispatch } from '../app/hooks';
import { updateSecondarySubset, updateWizardStepName, updateType } from '../slices/submitSlice';
import { BraceletStyle, JewelryBrand } from '../data/ItemDetails';
import DiamondNumber from '../components/steps/common/step_stoneNumber';
import StepBrand from '../components/steps/common/step_brand';
import TotalWeight from '../components/steps/common/step_totalWeight';
import StepRegistration from '../components/steps/common/step_registration';
import StepPhotoUpload from '../components/steps/common/step_photoUpload';
import StepStyle from '../components/steps/common/step_style';
import StepMetalType from '../components/steps/step_metalType';
import { loadGraph } from '../lib/wizard';
import { StepAdditionalInfo } from '../components/steps/common/step_additional_info';
import StepReject from '../components/steps/common/step_reject';
import StepSubmit from '../components/steps/common/step_submit';

const braceletStyleItems = [
  {
    img: 'images/icons/jewelry/bracelet/tennis.png',
    code: BraceletStyle.Tennis,
  },
  {
    img: 'images/icons/jewelry/bracelet/charm.png',
    code: BraceletStyle.Charm,
  },
  {
    img: 'images/icons/jewelry/bracelet/bangle.png',
    code: BraceletStyle.Bangle,
  },
  {
    img: 'images/icons/jewelry/bracelet/link.png',
    code: BraceletStyle.Link,
  },
  {
    img: 'images/icons/other.svg',
    code: BraceletStyle.Other,
  },
  // I'll be the judge of what's more readable:
  // eslint-disable-next-line prettier/prettier
].map((item) => ({ ...item, text: item.code })); // make item.text equal to item.code which is a capitalized string

export const createSteps = (): IStep2[] => [
  {
    comp: StepBrand,
    name: 'StepBrand',
    caption: 'Brand',
    disableBackButton: true,
    title: 'What brand is your bracelet?',
    exits: {
      premium: 'StepAdditionalInfo',
      next: 'StepBraceletStyle',
    },
    props: {
      premiumBrands: [
        JewelryBrand.Cartier,
        JewelryBrand.VanCleefArpens,
        JewelryBrand.Bvlgari,
        JewelryBrand.Tiffany,
        JewelryBrand.HarryWinston,
        JewelryBrand.Graff,
        JewelryBrand.DavidWebb,
        JewelryBrand.Verdura,
        JewelryBrand.Buccellati,
        JewelryBrand.OscarHeyman,
      ],
    },
  },
  {
    comp: StepStyle,
    name: 'StepBraceletStyle',
    caption: 'Bracelet Style',
    title: 'What is the style of your bracelet?',
    props: { items: braceletStyleItems },
  },
  {
    comp: StepMetalType,
    name: 'StepMetalType',
    caption: 'Metal Type',
    title: 'What metal is your bracelet made of?',
  },
  {
    comp: DiamondNumber,
    name: 'StepNumberOfStones',
    caption: 'Number of Stones',
    title: 'Great! How many diamonds are set in your bracelet?',
  },
  {
    comp: TotalWeight,
    name: 'StepTotalCaratWeight',
    caption: 'Carat Weight',
  },
  {
    comp: StepSubmit,
    name: 'StepSubmit',
    caption: 'Submit',
    disableBackButton: true,
    exits: {
      next: 'StepRegistration',
      reject: 'StepReject',
    },
  },
  {
    comp: StepReject,
    name: 'StepReject',
    caption: 'Reject',
    disappearFromProgressBar: true,
    disableProgressBar: true,
    disableBackButton: true,
    exits: {
      restart: 'StepBrand',
    },
  },
  {
    comp: StepRegistration,
    name: 'StepRegistration',
    caption: 'Register',
    itemRequired: true,
    skipOnlogin: true,
    disableBackButton: true,
  },
  {
    comp: StepPhotoUpload,
    name: 'StepPhotoUpload',
    caption: 'Upload photos',
    itemRequired: true,
    disappearFromProgressBar: true,
    disableProgressBar: true,
    disableBackButton: true,
    exits: {},
    final: true,
  },
  {
    comp: StepAdditionalInfo,
    name: 'StepAdditionalInfo',
    caption: 'Additional Info',
    title: 'Do you have the original box and paperwork?',
    exits: {
      next: 'StepSubmit',
    },
  },
];

function BraceletPage() {
  const dispatch = useAppDispatch();
  const steps = createSteps();

  dispatch(updateType('bracelet'));

  useEffect(() => {
    // default subset for bracelet to prevent wrong calculation for count of steps
    dispatch(updateSecondarySubset('diamond_center_stone'));
    dispatch(updateWizardStepName(steps[0].name));
  }, [dispatch, steps]);

  const stepsGraph = loadGraph(steps);

  return (
    <ThemeProvider theme={mainTheme}>
      <CommonLayout>
        <Wizard2 stepsGraph={stepsGraph} />
      </CommonLayout>
    </ThemeProvider>
  );
}

export default BraceletPage;
