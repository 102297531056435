/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Icon,
  Select,
  MenuItem,
  Box,
  styled,
  Stepper,
  StepConnector,
  StepLabel,
  Step as MUIStep,
  FormControl,
  InputLabel,
} from '@worthy-npm/worthy-common-ui-components';
import _ from 'lodash';
import { Step, StepContainer, NextStepButton, StepGridContainer } from '../../../styles/common';
import { useAppDispatch, useAppSelector, useSmallerVersion } from '../../../app/hooks';
import {
  selectCaratClassification,
  selectItemType,
  updateCarat,
  updateCaratClassification,
  updateCaratRangeTo,
  updateSetting,
  updateShape,
} from '../../../slices/submitSlice';
import { StepProps } from '../common';
import StepTitle from '../../stepTitle';
import GA from '../../../data/GA';
import {
  CaratSizeRanges,
  itemSettings,
  itemShapes,
  bucketTypes,
  CLASSIFICATION,
  stoneClassifications,
} from '../../../data/ItemDetails';
import { FormContent } from '../../../styles/commonText';
import { toCamelCase, toTitleCase } from '../../../lib/commonUtils';

interface IMandatorySteps {
  setting: boolean;
  carat: boolean;
  stoneClassification: boolean;
  shape: boolean;
}

const initialMandatorySteps: IMandatorySteps = {
  setting: false,
  carat: false,
  stoneClassification: false,
  shape: false,
};

function Star({ active, completed }: any) {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 1C8.80646 4.75556 11.7461 7.68565 15.5043 8.47986L15.5996 8.5L15.5043 8.52014C11.7461 9.31435 8.80646 12.2444 8 16L7.97956 15.9048C7.18461 12.2028 4.29889 9.3074 0.599609 8.5C4.29889 7.6926 7.18461 4.79716 7.97956 1.09518L8 1Z"
        fill="#F8FAFF"
        stroke={active || completed ? '#2D5DEC' : '#EAEFFF'}
        strokeWidth="2"
      />
    </svg>
  );
}

const DropdownStyle = (selected: boolean): React.CSSProperties => ({
  textAlign: 'start',
  color: selected ? 'black' : 'gray',
});
const MenuItemStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
};
const rowStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '10px',
};

const FlowWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  gap: '8px',
}));

const ColorlibConnector = styled(StepConnector)<{ completedStep?: number }>(({ theme }) => ({
  [`& .MuiStepConnector-line`]: {
    marginLeft: '28px',
    minHeight: '70px',
    borderLeftWidth: '2px',
    borderLeftStyle: 'solid',
    borderLeftColor: '#EAEFFF',
    transition: 'border-color 0.2s ease-in-out',
  },
  [`&.Mui-completed .MuiStepConnector-line, &.Mui-active .MuiStepConnector-line`]: {
    borderLeftColor: '#2D5DEC',
  },

  [theme.breakpoints.down('md')]: {
    [`& .MuiStepConnector-line`]: {
      marginLeft: '3px',
    },
  },
}));

const TextCurrentStep = styled('span')({
  color: '#2D5DEC',
  font: '700 16px Roboto',
});
const TextTotalStep = styled('span')({
  color: '#A8BEFF',
  font: '700 16px Roboto',
});

const StepperWrapper = styled(Stepper)(({ theme }) => ({
  marginBottom: '15%',
  color: '#47566D',

  [`& .MuiStepLabel-vertical`]: {
    flexDirection: 'row-reverse',
    padding: 0,
    height: '15px',
    gap: '8px',
  },

  [`& .MuiStepper-root`]: {
    marginRight: '8px',
  },

  '& .MuiStepConnector-root .MuiStepConnector-line': {
    height: '100%',
  },
}));

function SinglePageFlow({
  active,
  next,
  stepName,
  idx,
  prev,
  title,
  stepCaption,
  props,
}: StepProps) {
  const isMobile = useSmallerVersion();
  const [weight, setWeight] = useState<number>(0);
  const [weightRange, setWeightRange] = useState<string>('');
  const [setting, setSetting] = useState<string>('');
  const [shape, setShape] = useState<string>('');
  const [stoneClassification, setStoneClassification] = useState<string>('');
  const [mandatorySelected, setMandatorySelected] =
    useState<IMandatorySteps>(initialMandatorySteps);
  const [nextAvailability, setNextAvailability] = useState(false);
  const [busy, setBusy] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [floatingLabel, setFloatingLabel] = useState({
    setting: 'Select ring setting',
    shape: 'Select diamond shape',
    stoneClassification: 'Select stone type',
    carat: 'Select carat range',
  });

  const itemType = useAppSelector(selectItemType);
  const classification = useAppSelector(selectCaratClassification);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!active) return;

    if (itemType === 'Ring') {
      GA.stepView(
        stepName,
        undefined,
        undefined,
        undefined,
        undefined,
        'ringSubmissionFunnel',
        'singlePageSubmission',
      );
    } else {
      GA.stepView(stepName);
    }
  }, [active]);

  useEffect(() => {
    const checkAllSelected = () => {
      const allSelected = Object.values(mandatorySelected).every((value) => value === true);
      setNextAvailability(allSelected);
    };

    checkAllSelected();
  }, [mandatorySelected]);

  const handleSelectRange = (e: any) => {
    setWeightRange(e.target.value);

    const range = JSON.parse(e.target.value);
    const weightFrom = range.text === 'Not Sure' || !range.from ? 0 : range.from;
    setWeight(weightFrom);
    setMandatorySelected({ ...mandatorySelected, carat: true });
  };

  const getCaratDropdown = () => (
    <FormControl fullWidth>
      <InputLabel sx={{ color: '#999999' }} id="single-page-flow-carat-select-label">
        {floatingLabel.carat}
      </InputLabel>
      <Select
        labelId="single-page-flow-carat-select-label"
        id="single-page-flow-carat-select"
        data-automation="single-page-flow-carat-select"
        label={floatingLabel.carat}
        fullWidth
        value={weightRange}
        disabled={activeStep < 3}
        onChange={(e: any) => {
          handleSelectRange(e);
        }}
        onOpen={() => {
          setFloatingLabel((rest) => ({ ...rest, carat: 'Carat range' }));
        }}
        sx={{
          '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            border: '1px solid silver',
          },
        }}
        onBlur={() => {
          if (!weightRange.length) {
            setFloatingLabel((rest) => ({ ...rest, carat: 'Select carat range' }));
          }
        }}
        style={DropdownStyle(mandatorySelected.carat)}
        renderValue={() =>
          weightRange.length && JSON.parse(weightRange).text.length !== 0
            ? JSON.parse(weightRange).text
            : 'Select carat range'
        }
        displayEmpty
      >
        {CaratSizeRanges.map((range) => (
          <MenuItem
            key={`single-page-flow-carat-select-${range.text}`}
            value={`{"from": ${range.from}, "to": ${range.to}, "text": "${range.text}"}`}
            style={MenuItemStyle}
          >
            <div style={rowStyle}>{range.text}</div>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const getSettingDropdown = () => (
    <FormControl fullWidth>
      <InputLabel sx={{ color: '#999999' }} id="single-page-flow-setting-select-label">
        {floatingLabel.setting}
      </InputLabel>
      <Select
        labelId="single-page-flow-setting-select-label"
        id="single-page-flow-setting-select"
        data-automation="single-page-flow-setting-select"
        fullWidth
        value={setting}
        label={floatingLabel.setting}
        onChange={(e: any) => {
          const selection = e.target.value;
          const eventStepName = 'StepSettings';
          if (itemType === 'Ring') {
            GA.select({ step: eventStepName, value: selection, value3: 'settingStepNext' });
          } else {
            GA.select({ step: eventStepName, value: selection });
          }
          setSetting(selection);
          activeStep < 1 && setActiveStep(1);
          setMandatorySelected({ ...mandatorySelected, setting: true });
        }}
        onBlur={() => {
          if (!setting.length) {
            setFloatingLabel((rest) => ({ ...rest, setting: 'Select ring setting' }));
          }
        }}
        onOpen={() => {
          setFloatingLabel((rest) => ({ ...rest, setting: 'Ring setting' }));
          GA.stepView(
            'StepSettings',
            undefined,
            undefined,
            undefined,
            undefined,
            'ringSubmissionFunnel',
            'settingsStepView',
          );
        }}
        sx={{
          '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            border: '1px solid silver',
          },
        }}
        style={DropdownStyle(mandatorySelected.setting)}
        renderValue={() => toTitleCase(setting) || 'Select ring setting'}
        displayEmpty
      >
        {itemSettings.map((val) => (
          <MenuItem
            key={`single-page-flow-setting-select-${val.code}`}
            value={val.code}
            style={MenuItemStyle}
          >
            <div style={rowStyle}>
              <img src={val.icon} alt={val.code} height="20px" />
              <span>{toTitleCase(val.code)}</span>
            </div>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const getStoneClassificationDropdown = () => (
    <FormControl fullWidth>
      <InputLabel sx={{ color: '#999999' }} id="single-page-flow-stone-type-select-label">
        {floatingLabel.stoneClassification}
      </InputLabel>
      <Select
        labelId="single-page-flow-stone-type-select-label"
        id="single-page-flow-stone-type-select"
        data-automation="single-page-flow-stone-type-select"
        label={floatingLabel.stoneClassification}
        fullWidth
        value={stoneClassification}
        disabled={activeStep < 2}
        onChange={(e: any) => {
          setStoneClassification(e.target.value);
          activeStep < 3 && setActiveStep(3);
          setMandatorySelected({ ...mandatorySelected, stoneClassification: true });
        }}
        onOpen={() => {
          setFloatingLabel((rest) => ({ ...rest, stoneClassification: 'Stone type' }));
          const eventStepName = 'StepCarat';
          if (itemType === 'Ring') {
            GA.stepView(
              eventStepName,
              undefined,
              undefined,
              undefined,
              undefined,
              'ringSubmissionFunnel',
              'caratStepView',
            );
          } else {
            GA.stepView(eventStepName);
          }
        }}
        onBlur={() => {
          if (!stoneClassification.length) {
            setFloatingLabel((rest) => ({ ...rest, stoneClassification: 'Select stone type' }));
          }
        }}
        sx={{
          '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            border: '1px solid silver',
          },
        }}
        style={DropdownStyle(mandatorySelected.stoneClassification)}
        renderValue={() => {
          if (!stoneClassification) {
            return 'Select stone type';
          }
          return `${toTitleCase(stoneClassification)} ${
            stoneClassification === 'Gemstone' ? '' : 'Diamond'
          }`;
        }}
        displayEmpty
      >
        {stoneClassifications.map((val, index) => (
          <MenuItem
            key={`single-page-flow-stone-type-select-${val.text}`}
            value={val.text}
            style={MenuItemStyle}
          >
            <div style={rowStyle}>
              <img src={val.img} alt={val.text} height="20px" />
              <span>
                {toTitleCase(val.text)} {val.text === 'Gemstone' ? '' : 'Diamond'}
              </span>
            </div>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const getShapeDropdown = () => (
    <FormControl fullWidth>
      <InputLabel sx={{ color: '#999999' }} id="single-page-flow-shape-select-label">
        {floatingLabel.shape}
      </InputLabel>
      <Select
        labelId="single-page-flow-shape-select-label"
        id="single-page-flow-shape-select"
        data-automation="single-page-flow-shape-select"
        label={floatingLabel.shape}
        fullWidth
        disabled={activeStep < 1}
        value={shape}
        onChange={(e: any) => {
          const selection = e.target.value;
          const eventStepName = 'StepShape';
          if (itemType === 'Ring') {
            GA.select({ step: eventStepName, value: selection, value3: 'shapeStepNext' });
          } else {
            GA.select({ step: eventStepName, value: selection });
          }
          setShape(selection);
          activeStep < 2 && setActiveStep(2);
          setMandatorySelected({ ...mandatorySelected, shape: true });
        }}
        onBlur={() => {
          if (!shape.length) {
            setFloatingLabel((rest) => ({ ...rest, shape: 'Select diamond shape' }));
          }
        }}
        onOpen={() => {
          setFloatingLabel((rest) => ({ ...rest, shape: 'Diamond shape' }));
          const eventStepName = 'StepShape';
          if (itemType === 'Ring') {
            GA.stepView(
              eventStepName,
              undefined,
              undefined,
              undefined,
              undefined,
              'ringSubmissionFunnel',
              'shapeStepView',
            );
          } else {
            GA.stepView(eventStepName);
          }
        }}
        sx={{
          '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            border: '1px solid silver',
          },
        }}
        style={DropdownStyle(mandatorySelected.shape)}
        renderValue={() => toTitleCase(shape) || 'Select diamond shape'}
        displayEmpty
      >
        {itemShapes.map((val) => (
          <MenuItem
            key={`single-page-flow-shape-select-${val.text}`}
            value={val.text}
            style={MenuItemStyle}
          >
            <div style={rowStyle}>
              <img src={val.img} alt={val.code} height="20px" />
              <span>{toTitleCase(val.text)}</span>
            </div>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const handleContinueClicked = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!busy) {
      setBusy(true);
      dispatch(updateCarat(weight));
      dispatch(updateSetting(setting));
      dispatch(updateShape(shape));
      dispatch(updateCaratClassification(stoneClassification as CLASSIFICATION));

      if (bucketTypes.includes(itemType)) {
        const range = JSON.parse(weightRange);
        const caratClass = _.find(stoneClassifications, { text: classification })?.eventText || '';

        if (range.text) {
          const rangeCamel = range.text.replace(' ct.', '');
          GA.submitCaratWeight(toCamelCase(rangeCamel), caratClass);
          dispatch(updateCaratRangeTo(range.to));
        }
      } else {
        GA.submitNext(stepName, weight.toString());
      }

      next(e);
      setTimeout(() => {
        setBusy(false);
      }, 150);
    }
  };

  return (
    <StepContainer data-automation="single-page-flow-step">
      <Step>
        <StepTitle
          idx={idx}
          stepCaption={stepCaption}
          prev={prev}
          stepTitle={title || 'Tell us about your ring'}
          stepName={stepName}
          stepNotice="Select below the options relevant to your item"
          optionalProps={props || {}}
        />
        <StepGridContainer style={{ maxWidth: '600px' }}>
          <FlowWrapper sx={{ paddingRight: '38px' }}>
            <StepperWrapper
              orientation="vertical"
              connector={<ColorlibConnector />}
              activeStep={activeStep}
            >
              {new Array(4).fill(0).map((label, index) => (
                <MUIStep key={label}>
                  <StepLabel
                    StepIconComponent={Star}
                    StepIconProps={{
                      active: index === activeStep,
                      completed: index < activeStep,
                    }}
                  >
                    {activeStep === index && !isMobile && (
                      <>
                        <TextCurrentStep>{index + 1}</TextCurrentStep>
                        <TextTotalStep>/4</TextTotalStep>
                      </>
                    )}
                  </StepLabel>
                </MUIStep>
              ))}
            </StepperWrapper>

            <Box sx={{ marginLeft: '5px' }}>
              <FormContent>
                {getSettingDropdown()}
                {getShapeDropdown()}
                {getStoneClassificationDropdown()}
                {getCaratDropdown()}
              </FormContent>
              <NextStepButton>
                <Button
                  disableElevation
                  data-automation="single-page-flow-next-button"
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleContinueClicked(e)}
                  variant="contained"
                  size="large"
                  disabled={!nextAvailability}
                  endIcon={<Icon.ContinueIcon />}
                >
                  Continue
                </Button>
              </NextStepButton>
            </Box>
          </FlowWrapper>
        </StepGridContainer>
      </Step>
    </StepContainer>
  );
}

export default SinglePageFlow;
