/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect } from 'react';
import {
  Button,
  TextField,
  Box,
  Checkbox,
  Typography,
  FormControlLabel,
  FormGroup,
} from '@worthy-npm/worthy-common-ui-components';
import { IMask } from 'react-imask';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import styled from '@emotion/styled/macro';
import { useAppSelector } from '../../../app/hooks';
import { IRegistration, selectRegister, selectSubmitAPI } from '../../../slices/submitSlice';
import { CookieStore } from '../../../services/cookieStore';
import { RunningText } from '../../../styles/commonText';

export const ErrorMessage = styled(RunningText)`
  color: red;
  margin-bottom: 20px;
  text-align: center;
  display: inherit;
`;
const Link = styled.a`
  color: inherit;
  text-decoration: none;
  cursor: pointer;
`;

const InputField = styled(TextField)`
  input {
    outline: none !important;
  }
`;

const schema = yup.object().shape({
  firstName: yup
    .string()
    .required('Required field')
    .matches(
      /^[aA-zZ]+[aA-zZ_.,'‘’“”" -]*$/,
      "Allowed characters: Alphabet, space and . ” , '  _ -",
    )
    .min(2, 'Minimum 2 characters')
    .max(64, 'Maximum 64 characters'),
  lastName: yup
    .string()
    .required('Required field')
    .matches(
      /^[aA-zZ]+[aA-zZ_.,'‘’“”" -]*$/,
      "Allowed characters: Alphabet, space and . ” , '  _ -",
    )
    .min(2, 'Minimum 2 characters')
    .max(64, 'Maximum 64 characters'),
  email: yup
    .string()
    .required('Required field')
    .email('Enter a valid email (you@domain.com)')
    .max(64, 'Maximum 64 characters'),
  phoneNumber: yup.string().when('$forcePhoneNumber', {
    is: true,
    then: (localSchema) =>
      localSchema
        .required('Required field')
        .max(14, 'Should be a valid phone number. e.g. (423) 456-7890')
        .min(14, 'Should be a valid phone number. e.g. (423) 456-7890'),
    otherwise: (localSchema) =>
      localSchema
        .notRequired()
        .max(14, 'Should be a valid phone number. e.g. (423) 456-7890')
        .min(14, 'Should be a valid phone number. e.g. (423) 456-7890')
        .nullable()
        .transform((value) => value || null),
  }),
});

const isEmailErrorMsg = (msg: string): boolean => {
  return msg === 'Email already taken. Please Log in instead';
};

const normalizePhoneNumber = (value: string, mask = true) => {
  const masked = IMask.createMask({
    mask: '(#00) 000-0000',
    definitions: {
      '#': /[2-9]/,
    },
  });
  masked.resolve(value);
  return mask ? masked.value : masked.unmaskedValue;
};

interface IStepRegistrationFormProps {
  onSubmit: (data: IRegistration, smsConsent: boolean) => void;
  verificationView: boolean;
  apiErrorMsg: string;
  idx: number;
  stepName: string;
}

type MandatoryFields = {
  key: 'firstName' | 'lastName' | 'email' | 'phoneNumber';
  label: string;
  inputMode: 'text' | 'email' | 'tel';
};

function StepRegistrationForm(props: IStepRegistrationFormProps) {
  const { verificationView, apiErrorMsg } = props;
  const registration = useAppSelector(selectRegister);
  const { forcePhoneNumber } = useAppSelector(selectSubmitAPI);
  const isMandatoryPhoneLabelExperiment = CookieStore.getMandatoryPhoneLabel();
  const [checked, setChecked] = React.useState(false);

  const {
    setError,
    watch,
    reset,
    trigger,
    getFieldState,
    register,
    setValue,
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: { firstName: '', lastName: '', email: '', phoneNumber: '' },
    mode: 'onBlur',
    resolver: yupResolver(schema),
    context: {
      forcePhoneNumber: forcePhoneNumber || isMandatoryPhoneLabelExperiment,
    },
  });

  useEffect(() => {
    reset({
      firstName: registration.firstName,
      lastName: registration.lastName,
      email: registration.email,
    });

    if (isEmailErrorMsg(apiErrorMsg)) {
      setError('email', { message: apiErrorMsg });
    }
  }, [registration, apiErrorMsg]);

  const onSubmitForm = (data: IRegistration) => {
    const { onSubmit } = props;
    onSubmit(data, checked);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const mandatoryFields: MandatoryFields[] = [
    { key: 'firstName', label: 'First Name', inputMode: 'text' },
    { key: 'lastName', label: 'Last Name', inputMode: 'text' },
    { key: 'email', label: 'Email', inputMode: 'email' },
  ];

  return (
    <form onSubmit={handleSubmit(onSubmitForm)} noValidate>
      {mandatoryFields.map((field) => (
        <Controller
          key={field.key}
          name={field.key}
          control={control}
          defaultValue=""
          rules={{
            onChange: () => {
              const { invalid, isTouched } = getFieldState(field.key);
              if (invalid || isTouched) {
                trigger(field.key);
              }
            },
          }}
          render={({ field: controllerField, formState, fieldState }) => (
            <InputField
              {...controllerField}
              style={{ minHeight: '98px' }}
              fullWidth
              disabled={verificationView}
              label={field.label}
              required
              data-automation={`input-field-${field.key}`}
              id={`input-field-${field.key}`}
              InputLabelProps={watch(controllerField.name) ? { shrink: true } : {}}
              inputProps={{
                inputMode: field.inputMode,
                type: field.inputMode,
                onChange: (e) => {
                  const prevValue = getValues(field.key);
                  // @ts-ignore
                  const value = e.target.value;

                  const isAutocomplete =
                    value.length - prevValue.length > 1 && !fieldState.isTouched;
                  if (isAutocomplete) {
                    controllerField.onBlur();
                  }
                  controllerField.onChange(e);
                },
              }}
              error={!!errors[controllerField.name]}
              helperText={errors?.[controllerField.name]?.message}
              placeholder={field.label}
            />
          )}
        />
      ))}

      <InputField
        {...register('phoneNumber', {
          onChange: (e) => {
            e.target.value = normalizePhoneNumber(e.target.value);
            setValue('phoneNumber', normalizePhoneNumber(e.target.value));
            const { invalid, isTouched } = getFieldState('phoneNumber');
            if (invalid || isTouched) {
              trigger('phoneNumber');
            }
          },
        })}
        autoFocus={verificationView}
        style={{ minHeight: '98px' }}
        fullWidth
        label="Phone Number"
        name="phoneNumber"
        required={forcePhoneNumber || isMandatoryPhoneLabelExperiment}
        data-automation="input-field-phoneNumber"
        inputProps={{
          inputMode: 'tel',
          type: 'tel',
        }}
        error={!!errors.phoneNumber}
        helperText={errors?.phoneNumber?.message}
        placeholder="(423) 456-7890"
      />

      {apiErrorMsg && !isEmailErrorMsg(apiErrorMsg) && <ErrorMessage>{apiErrorMsg}</ErrorMessage>}

      <FormGroup sx={{ marginBottom: '24px' }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={handleChange}
              sx={{ alignSelf: 'flex-start', paddingTop: '0', color: '#5A83FF' }}
            />
          }
          label={
            <Typography variant="body2" fontSize="14px">
              I agree to receive text messages regarding the sale of my item and promotional
              messages from Worthy at the phone number provided. Msg & data rates may apply. Msg
              frequency may vary. Reply STOP to end messages. Help at
              <Link href="tel:+18882220208"> 888-222-0208</Link>
            </Typography>
          }
        />
      </FormGroup>

      <Box>
        {verificationView ? (
          <Button
            disableElevation
            fullWidth
            type="submit"
            data-automation="verification-button"
            variant="contained"
            size="large"
          >
            Continue
          </Button>
        ) : (
          <Button
            disableElevation
            fullWidth
            type="submit"
            data-automation="registration-button"
            variant="contained"
            size="large"
          >
            Sign Up
          </Button>
        )}
      </Box>
    </form>
  );
}

export default StepRegistrationForm;
