/* eslint-disable prettier/prettier */
import _ from 'lodash';
import type { IStep, IStep2 } from '../components/wizard';
import { StepProps } from '../components/steps/common';
import type { SubmitState } from '../slices/submitSlice';

export const visibleStep = (step: IStep, isLoggedIn: boolean, secondarySubset = '') =>
  !step.hidden &&
    !(isLoggedIn && step.skipOnlogin) &&
    (!step.secondarySubset || step.secondarySubset === secondarySubset);

export const nextVisibleStepIndex = (
  steps: any[],
  currentIndex: number,
  isLoggedIn: boolean,
  secondarySubset = '',
) =>
  steps.findIndex((step, i) => visibleStep(step, isLoggedIn, secondarySubset) && i > currentIndex);

export const prevVisibleStepIndex = (
  steps: any[],
  currentIndex: number,
  isLoggedIn: boolean,
  secondarySubset = '',
) =>
  _.findLastIndex(
    steps,
    (step, i) => visibleStep(step, isLoggedIn, secondarySubset) && i < currentIndex,
    currentIndex - 1,
  );

export const getSubmitIndex = (steps: IStep[], isLoggedIn: boolean, secondarySubset = '') => {
  let index = 0;
  if (_.isEmpty(steps)) {
    throw new Error('steps is empty');
  }
  for (let i = 0; i < steps.length; i += 1) {
    const step = steps[i];
    if (visibleStep(step, isLoggedIn, secondarySubset)) {
      if (step.itemRequired) {
        return index;
      }
      index = i;
    }
  }

  return index;
};

export const getVisibleSteps = (steps: IStep[], isLoggedIn: boolean, secondarySubset = '') => {
  return _.filter(steps, (step) => visibleStep(step, isLoggedIn, secondarySubset));
};

export const getProgressStepCount = (steps: IStep[], isLoggedIn: boolean, secondarySubset = '') =>
  getVisibleSteps(steps, isLoggedIn, secondarySubset).filter(
    (step) => !step.disappearFromProgressBar,
  ).length;

export const getProgressStepIndex = (visibleSteps: Array<IStep>, step: IStep) => {
  let index = 0;

  visibleSteps.find((s) => {
    if (!s.disappearFromProgressBar) index += 1;
    return s.name === step.name;
  });
  return index;
};


//-------

export type VertexId = string;

export interface LabeledEdge {
    destination: VertexId;
	guard?: ((state: SubmitState) => boolean);
    label: string
}

type HiddenFn = (state: any) => boolean;
type OnEnterLeaveFn = (state: any) => void;

export type Vertex = {
  id: VertexId;
  onEnterFns?: OnEnterLeaveFn[];
  onLeaveFns?: OnEnterLeaveFn[];
  hiddenFns?: HiddenFn[];
  edges: Map<VertexId, LabeledEdge>;
  // component: React.ComponentType<any>;
  step: IStep2;
  flags?: {
    submit?: boolean;
    final?: boolean;
    disableProgressBar?: boolean;
  };
};

export class LabeledDirectedGraph {
  private vertices: Map<VertexId, Vertex>;

  public root: VertexId = '';

  constructor() {
    this.vertices = new Map<VertexId, Vertex>();
  }

  addVertex(vertex: Vertex): void {
    if (!this.vertices.has(vertex.id)) {
      this.vertices.set(vertex.id, vertex);
    }
  }

  addEdge(sourceId: VertexId, destinationId: VertexId, label: string): void {
    const sourceVertex = this.vertices.get(sourceId);
    if (!sourceVertex) {
      throw new Error(`Source vertex ${sourceId} does not exist.`);
    }
    sourceVertex.edges.set(label, { destination: destinationId, label });
  }

  getVertex(id: VertexId): Vertex | undefined {
    return this.vertices.get(id);
  }

  getRootVertex(): Vertex | undefined {
    return this.getVertex(this.root);
  }

  getForwardEdges(id: VertexId): LabeledEdge[] {
    const vertex = this.vertices.get(id);
    const edgeLabelsToExclude = ['prev', 'reject'];
    if (!vertex) {
      throw new Error(`Vertex ${id} does not exist.`);
    }
    return Array.from(vertex.edges.entries())
      .filter(([label, _edge]) => !edgeLabelsToExclude.includes(label))
      .map(([_label, edge]) => edge);
  }

  depthForVertex(id: VertexId): number {
    const vertex = this.vertices.get(id);
    if (!vertex) {
      throw new Error(`Vertex ${id} does not exist.`);
    }
    const forwardEdges = this.getForwardEdges(id);
    if (forwardEdges.length === 0) {
      return 0;
    }
    const childrenDepth = Math.min(
      ...forwardEdges.map((edge) => this.depthForVertex(edge.destination)),
    );
    return childrenDepth + 1;
  }
}


const hideIfLoggedIn = (state: SubmitState) => state?.user?.isLoggedIn || false;

export const loadCompatibleGraph = (steps: IStep[], SubmitStep: IStep) => {
  const graph = new LabeledDirectedGraph();
  const addVertexForStep = (step: IStep) => {
    const vertex: Vertex = {
      id: step.name,
      step,
      edges: new Map<string, LabeledEdge>(),
      hiddenFns: [],
      onEnterFns: [],
      onLeaveFns: [],
    };
    if (step.skipOnlogin) {
      vertex.hiddenFns?.push(hideIfLoggedIn);
    }
    if(step.secondarySubset) {
      const subset = step.secondarySubset;
      vertex.hiddenFns?.push((state: SubmitState) => {
        return state?.secondarySubset !== subset
      });
    }
    graph.addVertex(vertex);
    return vertex;
  }

  const submitStep = steps.find((step) => step.itemRequired); // undefined if no itemRequired step
  const finalStep = steps[steps.length - 1];
  let prevStep: IStep | null = null;

  steps.forEach((step, i) => {
    if (step === submitStep) {
      addVertexForStep(SubmitStep);
      if (prevStep) {
        graph.addEdge(prevStep.name, SubmitStep.name, 'next');
        graph.addEdge(SubmitStep.name, prevStep.name,  'prev');
      }
      prevStep = SubmitStep;
    }

    const newVertex = addVertexForStep(step);

    if (prevStep) {
      graph.addEdge(prevStep.name, step.name, 'next');
      graph.addEdge(step.name, prevStep.name, 'prev');
    }
    prevStep = step;

    if (step === finalStep) {
      newVertex.flags = { ...newVertex.flags, final: true };
    }
    newVertex.flags = { ...newVertex.flags, disableProgressBar: step.disableProgressBar };
  });

  graph.root = steps[0].name;

  return graph;
};


export const loadGraph = (steps: IStep2[]) => {
  const graph = new LabeledDirectedGraph();
  const addVertexForStep = (step: IStep2) => {
    const vertex: Vertex = {
      id: step.name,
      step,
      edges: new Map<string, LabeledEdge>(),
      hiddenFns: [],
      onEnterFns: [],
      onLeaveFns: [],
    };
    if (step.skipOnlogin) {
      vertex.hiddenFns?.push(hideIfLoggedIn);
    }
    if(step.secondarySubset) {
      const subset = step.secondarySubset;
      vertex.hiddenFns?.push((state: SubmitState) => {
        return state?.secondarySubset !== subset
      });
    }
    graph.addVertex(vertex);
    return vertex;
  }

  // const finalStep = steps[steps.length - 1];
  let prevStep: IStep2 | null = null;

  steps.forEach((step, i) => {
    const newVertex = addVertexForStep(step);

    if (prevStep) {
      if (!prevStep.exits) {
        graph.addEdge(prevStep.name, step.name, 'next');
      }
      graph.addEdge(step.name, prevStep.name, 'prev');
    }
    prevStep = step;

    if (step.final) {
      newVertex.flags = { ...newVertex.flags, final: true };
    }
    newVertex.flags = { ...newVertex.flags, disableProgressBar: step.disableProgressBar };
    if (step.exits) {
      Object.entries(step.exits).forEach(([exit, destination]) => {
        graph.addEdge(step.name, destination, exit);
      });
    }
  });


  graph.root = steps[0].name;

  return graph;
};
