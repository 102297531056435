/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import { mainTheme, ThemeProvider } from '@worthy-npm/worthy-common-ui-components';
import Wizard, { IStep } from '../components/wizard';
import { useAppDispatch } from '../app/hooks';
import { updateType } from '../slices/submitSlice';
import CommonLayout from './layout/commonLayout';
import newSettings from '../components/steps/common/step_setting';
import newShape from '../components/steps/common/step_shape';
import newCarat from '../components/steps/common/step_weight';
import newColor from '../components/steps/common/step_color';
import newClarity from '../components/steps/common/step_clarity';
import newStepPhotoUpload from '../components/steps/common/step_photoUpload';
import newStepRegistration from '../components/steps/common/step_registration';
import SinglePageFlow from '../components/steps/common/single_page_flow';
import { LoadingDiamondImg, LoadingOverlay } from '../components/overlay';
import { getExperiment } from '../lib/experiment';

const createSteps = (singlePageFlow = false): IStep[] => [
  {
    comp: SinglePageFlow,
    name: 'SinglePageSubmission',
    caption: '',
    title: 'Tell us about your ring',
    disableBackButton: true,
    staticProgressBar: true,
    disappearFromProgressBar: true,
    disableProgressBar: true,
    hidden: !singlePageFlow,
    props: { fontSize: '42px', fontFamily: 'Playfair Display' },
  },
  {
    comp: newSettings,
    name: 'StepSettings',
    caption: 'Ring Setting',
    title: 'What is the setting of your ring?',
    disableBackButton: true,
    hidden: singlePageFlow,
  },
  {
    comp: newShape,
    name: 'StepShape',
    caption: 'Center Diamond Shape',
    title: 'What is the shape of your center diamond?',
    disableBackButton: false,
    hidden: singlePageFlow,
  },
  {
    comp: newCarat,
    name: 'StepCarat',
    caption: 'Center Diamond Carat',
    title: 'Great! What is the carat weight of your center diamond?',
    hidden: singlePageFlow,
  },
  {
    comp: newColor,
    name: 'StepColor',
    caption: 'Center Diamond Color',
    hidden: true,
  },
  {
    comp: newClarity,
    name: 'StepClarity',
    caption: 'Diamond Clarity',
    hidden: true,
  },

  {
    comp: newStepRegistration,
    name: 'StepRegistration',
    caption: 'Register',
    itemRequired: true,
    skipOnlogin: true,
    disableBackButton: true,
  },
  {
    comp: newStepPhotoUpload,
    name: 'StepPhotoUpload',
    caption: 'Upload Photos',
    itemRequired: true,
    disappearFromProgressBar: true,
    disableProgressBar: true,
    disableBackButton: true,
  },
];

const ringSteps = (singlePageFlow: boolean) => {
  const steps = createSteps(singlePageFlow);

  if (singlePageFlow) return steps;
  steps.shift();

  return steps;
};

function RingPage() {
  const dispatch = useAppDispatch();
  const isSinglePageFlow =
    getExperiment('singlePageSubmissionV5') === 'var_1_single_page_submission_singlePage';

  const [isDOMInitialized, setIsDOMInitialized] = React.useState(false);

  const steps = ringSteps(isSinglePageFlow);

  useEffect(() => {
    dispatch(updateType('ring'));
    setIsDOMInitialized(true);
  }, [dispatch]);

  return (
    <ThemeProvider theme={mainTheme}>
      <CommonLayout>
        {isDOMInitialized ? (
          <Wizard steps={steps} />
        ) : (
          <LoadingOverlay background="#f6f8ff">
            <LoadingDiamondImg />
          </LoadingOverlay>
        )}
      </CommonLayout>
    </ThemeProvider>
  );
}

export default RingPage;
